import './src/css/index.css';
import './src/css/animations.css';

/**
 * Keep current page scroll position if route-state variable is set while navigating pages
 */
export const shouldUpdateScroll = ({ routerProps }) => {
  const disableScrollUpdate = routerProps.location?.state?.disableScrollUpdate;
  return !disableScrollUpdate;
};
